import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import { Padding, py, mb, pr } from "styled-components-spacing";
import { graphql } from "gatsby";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import Layout from "../components/Layout";
import Header from "../components/GatsbyHeader";
import Container from "../components/Container";
import Section from "../components/Section";
import Content, { HTMLContent } from "../components/Content";

const CardList = styled.ul`
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  flex-wrap: wrap;

  @supports (display: grid) {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(288px, 1fr));
    grid-gap: 1rem;

    ${breakpoint("tablet")`
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 2rem;
    `}

    ${breakpoint("widescreen")`
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 2rem;
    `}
  }
`;

const Card = styled.li`
  flex: 1 1 0;
  font-size: 0;
  overflow: hidden;

  box-sizing: border-box;
  flex-basis: 100%;
  max-width: 100%;

  &:not(:last-child) {
    ${mb(4)};
  }

  ${breakpoint("tablet")`
    flex-basis: 50%;
    max-width: 50%;
    
    &:nth-child(2n + 1) {
      ${pr(3)};
    }

    &:not(:last-child) {
      ${mb(4)};
    }
  `}

  @supports (display: grid) {
    max-width: none;
    margin-bottom: 0 !important;
    ${pr(0)};
    padding-right: 0 !important;
  }
`;

const CardText = styled.div`
  ${py(3)};
`;

export const ProjectsPageTemplate = ({
  title,
  content,
  image,
  projects,
  contentComponent
}) => {
  const PageContent = contentComponent || Content;
  return (
    <React.Fragment>
      <Header
        imageData={
          image && !!image.childImageSharp ? image.childImageSharp.fluid : image
        }
        title={title}
      />

      <Padding
        top={{ mobile: 4, desktop: 5 }}
        horizontal={{ mobile: 3, tablet: 4, desktop: 5 }}
        bottom={0}
      >
        <Container>
          <PageContent className="content" content={content} />
        </Container>
      </Padding>

      <Section>
        <CardList>
          {projects.map((project, index) => (
            <Card key={index}>
              <PreviewCompatibleImage
                imageInfo={project}
                alt={project.description}
              />
            </Card>
          ))}
        </CardList>
      </Section>
    </React.Fragment>
  );
};

ProjectsPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func
};

const ProjectsPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <ProjectsPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        image={post.frontmatter.image}
        projects={post.frontmatter.projects}
        content={post.html}
      />
    </Layout>
  );
};

ProjectsPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default ProjectsPage;

export const projectsPageQuery = graphql`
  query ProjectsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 1600, quality: 60) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        projects {
          image {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 60) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          description
        }
      }
    }
  }
`;
